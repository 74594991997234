body {
    margin: 0;
    font-family: sans-serif;
    height: 100svh;
    display: flex;
    flex-direction: column;
}

#root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}